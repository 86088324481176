import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import '@brainhubeu/react-carousel/lib/style.css'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

import { renderRichText } from 'gatsby-source-contentful/rich-text'

import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import handleViewport from 'react-in-viewport'

import Cta from 'components/content-modules/CallToAction'
import { ModalContainer } from 'reoverlay'
import EditInContentful from 'utils/EditInContentful'
import { Layout } from 'components/common/'
import { usePartnerData } from 'hooks/usePartnerData'
import useSticky from 'hooks/useSticky'
import { useGlobalUpdateContext } from '../store'
import Hero from '../components/Hero'
import Container from '../components/Container'
import SEO from '../components/common/SEO'

// import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import CardList from '../components/CardList'
import Testimonial from '../components/Testimonial'
import ImageSlider from '../components/Slider'

import ProgramStat from '../components/ProgramStat'

import StatBlock from '../components/content-modules/StatBlock'
import Group from '../components/common/Container/Group'

import CtaSectionTop from '../components/CtaSectionTopC'

const carouselSettings = {
  dot: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: 'linear',
}

// eslint-disable-next-line no-unused-vars
const relatedProgramSettings = {
  dot: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToScroll: 1,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        // initialSlide: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const RICHTEXT_OPTIONS = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a className="link" href={data.uri}>
        {children}
      </a>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.EMBEDDED_ASSET]: ({ data }) => <Img image={data.target.gatsbyImageData} alt={data.target.title} />,
  },
}

const PreFooterCTA = ({ discoverProgramImage, programTitle, availableMethodsOfStudyHypenated }) => (
  <Container id="discover-program">
    {discoverProgramImage && (
      <Img image={discoverProgramImage.gatsbyImageData} className="discoverBgImg" alt={discoverProgramImage.title} />
    )}
    <div className="wrapper centered">
      <h3>Discover the {programTitle}</h3>
      <p>
        Find meaningful success—both personally and professionally—with the{' '}
        {availableMethodsOfStudyHypenated && availableMethodsOfStudyHypenated.join(' or ')} {programTitle}. If you are
        ready to learn valuable skills for a more rewarding career, why wait?{' '}
        <strong>Request more information today</strong> and we will reach out to you with all the details.
      </p>
      <Cta buttonText="Request Information">Request Information</Cta>
    </div>
  </Container>
)

const ProgramTemplate = function ({ data, pageContext }) {
  const {
    spaceId,
    slug,
    // eslint-disable-next-line camelcase
    contentful_id,
    title,
    metaDescription,
    heroImage,
    tags,
    fullProgramName,
    description,
    availableMethodsOfStudy,
    creditHours,
    monthsToCompleteRange,
    monthsToCompleteType,
    programTracks,
    degree,
    whyMorganStateStats,
    preContentBlock,
    skillsAndJobs,
    careerDetails,
    carouselPreText,
    testimonialPreText,
    carouselContent,
    testimonial,
    financialAidOptions,
    discoverProgramImage,
    // relatedPrograms,
    // thumbnail,
    programDetailUrl,
  } = data.contentfulProgram
  const { shortName } = usePartnerData()
  // const previous = pageContext.prev
  // const { next } = pageContext
  const { basePath } = pageContext
  // eslint-disable-next-line camelcase
  const contentfulId = contentful_id

  const { isSticky, element } = useSticky()

  const normalizedCarousel =
    carouselContent &&
    carouselContent.map((item) => ({
      id: item.id,
      title: item.title,
      image: item.image,
      description: item.description,
    }))

  const convertOnCampus = (onCampus) => {
    if (onCampus === 'On Campus') {
      return 'On-Campus'
    }
    return onCampus
  }

  const maybePluralize = (count, noun, suffix = 's') => `${noun}${count !== 1 ? suffix : ''}`

  const statsCardsAmount = whyMorganStateStats && whyMorganStateStats.length

  const availableMethodsOfStudyHypenated =
    availableMethodsOfStudy &&
    availableMethodsOfStudy.map((item) => {
      if (item === 'On Campus') {
        return 'On-Campus'
      }
      return item
    })

  const DEGREES_TO_CONVERT_TO_MASTER = ['MBA', 'MArch', 'MCRP', 'MEng', 'MPH', 'MSW']
  const DEGREES_TO_CONVERT_TO_DOCTORATE = ['DEng']
  // if degreeAcronym is of DEGREES_TO_CONVERT_TO_MASTER or DEGREES_TO_CONVERT_TO_DOCTORATE, return Master, or Doctorate, otherwise return degree
  const degreeType = (degreeAcronym) => {
    if (DEGREES_TO_CONVERT_TO_MASTER.includes(degreeAcronym)) {
      return 'Master'
    }
    if (DEGREES_TO_CONVERT_TO_DOCTORATE.includes(degreeAcronym)) {
      return 'Doctorate'
    }
    return degreeAcronym
  }
  // eslint-disable-next-line no-unused-vars
  const PROGRAMNAME_TO_CONVERT_TO_OF = [
    'Architecture',
    'Business Administration',
    'Public Health',
    'Social Work',
    'City & Regional Planning',
    'City and Regional Planning',
    'Engineering',
  ]

  // if fullProgramName is 'Architecture' or 'Business Administration', return degree 'of' fullProgramName, else return degree 'in' fullProgramName
  const programTitle =
    fullProgramName === 'Architecture' ||
    fullProgramName === 'Business Administration' ||
    fullProgramName === 'Public Health' ||
    fullProgramName === 'Social Work' ||
    fullProgramName === 'City & Regional Planning' ||
    fullProgramName === 'City and Regional Planning' ||
    fullProgramName === 'Engineering'
      ? `${degreeType(degree)} of ${fullProgramName}`
      : `${degreeType(degree)} in ${fullProgramName}`

  const MemoizedNestedComponent = React.useCallback(
    ({ forwardedRef }) => (
      <div className="viewport-block" ref={forwardedRef}>
        <PreFooterCTA
          discoverProgramImage={discoverProgramImage}
          programTitle={programTitle}
          availableMethodsOfStudyHypenated={availableMethodsOfStudyHypenated}
        />
      </div>
    ),
    [discoverProgramImage, availableMethodsOfStudyHypenated, programTitle]
  )

  const CtaSectionBottom = handleViewport(MemoizedNestedComponent)

  const ChangeClassBottom = () => {
    let topCta
    if (window !== 'undefined') {
      topCta = document.querySelector('#request-info-cta')
    }
    topCta.classList.toggle('hidden')
  }
  const { degreeLevel } = data.site.siteMetadata

  const dispatch = useGlobalUpdateContext()
  useEffect(() => {
    dispatch({ type: 'SET_SLUG', payload: slug })
    dispatch({ type: 'SET_PROGRAM_DETAIL_URL', payload: programDetailUrl })
    dispatch({ type: 'SET_DEGREE_LEVEL', payload: degreeLevel })
  }, [dispatch, slug, programDetailUrl, degreeLevel])

  return (
    <Layout className="program-template">
      <SEO
        title={programTitle}
        description={metaDescription.metaDescription || `Earn a ${programTitle}`}
        // image={
        //   heroImage.gatsbyImageData.images.fallback.src ||
        //   thumbnail.gatsbyImageData.images.fallback.src
        // }
        image={heroImage.gatsbyImageData.images.fallback.src}
      />
      {/* <EditInContentful spaceId={spaceId} contentfulId={contentfulId} /> */}
      {process.env.NODE_ENV === 'development' && <EditInContentful spaceId={spaceId} contentfulId={contentfulId} />}
      <Hero title={title} image={heroImage} />

      {tags && <TagList tags={tags} basePath={basePath} />}
      <Container id="program-details" constraints="narrow">
        <h1>{title || programTitle}</h1>
        <div className="learning-mode">
          <p>Learning Mode:</p>
          <ul className="no-list">
            {availableMethodsOfStudy &&
              availableMethodsOfStudy.map((method) => <li key={method}>{convertOnCampus(method)}</li>)}
          </ul>
        </div>
        <div className="program-intro">{metaDescription && <p>{metaDescription.metaDescription}</p>}</div>
        <div className="program-stats">
          {creditHours && <ProgramStat stat={creditHours} description={maybePluralize(creditHours, 'Credit Hour')} />}
          {monthsToCompleteRange && (
            <ProgramStat stat={monthsToCompleteRange} description={`${monthsToCompleteType} to Complete`} />
          )}
          {programTracks && (
            <ProgramStat stat={programTracks} description={maybePluralize(programTracks, 'Program Track')} />
          )}
        </div>
      </Container>

      <CtaSectionTop sticky={isSticky} />

      <Container className="cols" constraints="center" element={element}>
        <Group className="program-description cols">
          {description && renderRichText(description, RICHTEXT_OPTIONS)}
        </Group>
      </Container>
      <Container id="whyMorganState" className="drkbg">
        <Container constraints="center">
          <h2>Why {shortName}?</h2>
          <CardList rows={`${statsCardsAmount <= 4 ? 'one-row' : 'two-rows'}`}>
            {whyMorganStateStats && whyMorganStateStats.map((node) => <StatBlock key={node.id} data={node} />)}
          </CardList>
        </Container>
      </Container>
      <Container constraints="center" className="cols-container">
        {preContentBlock && (
          <Group className="wrapper centered narrow">{renderRichText(preContentBlock, RICHTEXT_OPTIONS)}</Group>
        )}
      </Container>

      <Container constraints="center" className="cols-container">
        {skillsAndJobs && <Group className="cols">{renderRichText(skillsAndJobs, RICHTEXT_OPTIONS)}</Group>}

        {careerDetails && <Group className="cols">{renderRichText(careerDetails, RICHTEXT_OPTIONS)}</Group>}
      </Container>

      <Container constraints="center">
        {carouselPreText && (
          <Group className="wrapper centered narrow">{renderRichText(carouselPreText, RICHTEXT_OPTIONS)}</Group>
        )}
        {normalizedCarousel && (
          <ImageSlider className="research-interships" data={normalizedCarousel} settings={carouselSettings} />
        )}
      </Container>

      <Container id="testimonials">
        {testimonialPreText && (
          <Group className="wrapper centered narrow">{renderRichText(testimonialPreText, RICHTEXT_OPTIONS)}</Group>
        )}
        {testimonial && (
          <Testimonial
            image={testimonial.image}
            quote={testimonial.quoteBlock.quoteBlock}
            // quote={renderRichText(testimonial.quote, RICHTEXT_OPTIONS)}
            author={testimonial.author}
          />
        )}
      </Container>

      <Container constraints="center" className="cols-container">
        {financialAidOptions && <Group>{renderRichText(financialAidOptions, RICHTEXT_OPTIONS)}</Group>}
      </Container>

      <CtaSectionBottom
        onEnterViewport={() => {
          ChangeClassBottom()
        }}
        onLeaveViewport={() => {
          ChangeClassBottom()
        }}
      />

      <Container id="relatedPrograms" constraints="center">
        {/* <Group>
            {normalizedRelated && (
              <div>
                <h2>Explore Related Programs</h2>
                <ImageSlider className="related-programs" data={normalizedRelated} settings={relatedProgramSettings} />
              </div>
            )}
          </Group> */}
        <div className="buttons centered">
          <Link to="/" className="button">
            View all programs
          </Link>
        </div>
      </Container>
      <ModalContainer />
    </Layout>
  )
}
export default ProgramTemplate

export const query = graphql`
  query ProgramPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        degreeLevel
        description
      }
    }
    contentfulProgram(slug: { eq: $slug }) {
      spaceId
      slug
      contentful_id
      fullProgramName
      title
      description {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 600)
          }
        }
      }
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH)
        title
      }
      availableMethodsOfStudy
      creditHours
      monthsToCompleteRange
      monthsToCompleteType
      programTracks
      programDetailUrl
      # thumbnail {
      #   gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 600)
      # }
      degree
      whyMorganStateStats {
        ... on ContentfulComponentStatBlocks {
          id
          internal {
            type
          }
          statisticImage {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 400)
          }
          statistic {
            id
            childMarkdownRemark {
              html
            }
          }
        }
      }
      programDetailUrl
      skillsAndJobs {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 640)
          }
        }
      }
      preContentBlock {
        raw
      }
      careerDetails {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 640)
          }
        }
      }
      carouselPreText {
        raw
      }
      financialAidOptions {
        raw
      }
      carouselContent {
        id
        title
        description
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 1080)
          title
        }
      }
      testimonialPreText {
        raw
      }
      testimonial {
        author
        quoteBlock {
          quoteBlock
        }
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 800)
          title
        }
      }
      metaDescription {
        metaDescription
      }
      discoverProgramImage {
        gatsbyImageData(placeholder: BLURRED, width: 800)
        title
      }
    }
  }
`
