import { useEffect, useState, useRef } from 'react'

function useSticky() {
  const [isSticky, setSticky] = useState(false)
  const element = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      if (element.current) {
        setSticky(window.scrollY > element.current.offsetTop)
      }
      // window.scrollY > element.current.getBoundingClientRect().bottom ? setSticky(true) : setSticky(false)
    }

    // This function handles the scroll performance issue
    const debounce = (func, wait = 20, immediate = true) => {
      let timeOut
      return () => {
        const context = this
        // eslint-disable-next-line prefer-rest-params
        const args = arguments
        const later = () => {
          timeOut = null
          if (!immediate) func.apply(context, args)
        }
        const callNow = immediate && !timeOut
        clearTimeout(timeOut)
        timeOut = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
      }
    }

    window.addEventListener('scroll', debounce(handleScroll))
    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  return { isSticky, element }
}

export default useSticky
