/* eslint-disable react/no-danger */
import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const SingleSlide = ({ item }) => (
  <div className="slick-slide-content">
    {item.image && <Img image={item.image.gatsbyImageData} alt={item.image.title} as="figure" />}
    <div className="slick-caption">
      <h4>{item.title}</h4>
      <p dangerouslySetInnerHTML={{ __html: item.description }} />
    </div>
  </div>
)

const ImageSlider = ({ data, settings, className }) => {
  // don't show more than 4 at a time
  const slidesToShow = data.length < 4 ? data.lenght : 4
  const internalSettings = {
    slidesToShow,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    ...settings,
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Slider className={className} {...internalSettings}>
      {data.map((item, i) =>
        // if item.slug exists, then it is a link to a page
        // if item.slug does not exist, then no link
        item.slug ? (
          <Link to={`/program/${item.slug}`} key={i}>
            <SingleSlide item={item} />
          </Link>
        ) : (
          <SingleSlide item={item} key={i} />
        )
      )}
    </Slider>
  )
}
export default ImageSlider
