import React from 'react'
import Cta from 'components/content-modules/CallToAction'
import { usePartnerData } from 'hooks/usePartnerData'
import Container from './Container'

const CtaSectionTopC = ({ sticky }) => {
  const { shortName } = usePartnerData()

  return (
    <div id="request-info-cta" className={`viewport-block ${sticky ? ' sticked' : 'site-header'}`}>
      <Container>
        <div className="cta-content narrow">
          <Cta
            descriptiveText={`
                <p>
                  Take the next step in your academic and professional career
                  with ${shortName}. <strong>Pursue your future &ndash; today.</strong>
                </p>
              `}
          >
            Request Information
          </Cta>
        </div>
      </Container>
    </div>
  )
}

export default CtaSectionTopC
