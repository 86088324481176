import React from 'react'

const EditInContentful = ({ spaceId, contentfulId }) => (
  <a
    style={{
      background: 'red',
      color: 'black',
      position: 'absolute',
      top: '0',
      right: '0',
      zIndex: '9999',
    }}
    // eslint-disable-next-line camelcase
    href={`https://app.contentful.com/spaces/${spaceId}/entries/${contentfulId}`}
    target="_blank"
    rel="noreferrer"
  >
    Edit in Contentful
  </a>
)

export default EditInContentful
